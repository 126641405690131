import Stopka2 from "../utils/stopka2";
import GalMalczewskiego from "./galleries/gal-malczewskiego";

function Malczewskiego(props) {
  return (
    <>
      <div className="App-row px-5 pt-28">
        <h1 className="text-2xl font-bold">Malczewskiego</h1>
        <p className="text-sm pb-16">Warszawa</p>
        <GalMalczewskiego />
        { props.children }
      </div>
      <Stopka2 />
    </>
  );
}

export default Malczewskiego;
