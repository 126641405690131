import klobucka_foto from "./../../../img/projekty/miniatures/klobucka.jpg";
import litewska_foto from "./../../../img/projekty/miniatures/litewska.jpg";
import malczewskiego_foto from "./../../../img/projekty/miniatures/malczewskiego.jpg";
import melomanow_foto from "./../../../img/projekty/miniatures/melomanow.jpg";
import smolenskiego_foto from "./../../../img/projekty/miniatures/smolenskiego.jpg";
import zdziarska_foto from "./../../../img/projekty/miniatures/zdziarska.jpg";
import posagusiedmiupanien_foto from "./../../../img/projekty/miniatures/posagusiedmiupanien.jpg";


export const klobuckaStep = {
  text: "Kłobucka",
  link: '/klobucka',
  photo: klobucka_foto
}

export const litewskaStep = {
  text: "Litewska",
  link: '/litewska',
  photo: litewska_foto
}

export const malczewskiegoStep = {
  text: "Malczewskiego",
  link: '/malczewskiego',
  photo: malczewskiego_foto
}

export const melomanowStep = {
  text: "Melomanów",
  link: '/melomanow',
  photo: melomanow_foto
}

export const smolenskiegoStep = {
  text: "Smoleńskiego",
  link: '/smolenskiego',
  photo: smolenskiego_foto
}

export const zdziarskaStep = {
  text: "Zdziarska",
  link: '/zdziarska',
  photo: zdziarska_foto
}

export const posagusiedmiupanienStep = {
  text: "Posag Siedmiu Panien",
  link: '/posagusiedmiupanien',
  photo: posagusiedmiupanien_foto
}

