import { Link } from "react-router-dom";
import Karuzela from "./karuzela";
import Skontaktuj from "./utils/skontaktuj";
import mainphoto from "./../img/people/main_foto.jpg";
import arrowR from "./../img/arr_right.png";
import Stopka1 from "./utils/stopka1";
import { useEffect, useRef } from "react";

function Main() {
  const imageRef = useRef(null);

  useEffect(() => {
    const image = imageRef.current;
      image.classList.add('loaded');
  }, []);

  return (
    <div className="App-row">
      <div ref={imageRef} className="flex flex-col md:flex-row px-5 items-center fade-in">
          <div className="md:w-1/2 pb-5 pb-20 pr-10">
            <h1 className="Main-haslo font-ajj text-4xl font-bold pt-20 md:pt-0 mb-10 pb-3">Agencja z pasją do nieruchomości i wnętrz.</h1>
            <Skontaktuj ></Skontaktuj>
          </div>
          <div className="md:w-1/2 flex flex-col text-left pt-10 md:pl-10">
            <img className="" src={mainphoto} />
        </div>
      </div>


      <div className="flex flex-col md:flex-row pl-5 mt-20">
        <div className="md:w-1/4 pb-5">
          <h1 className="text-3xl">O Nas</h1>
        </div>
        <div className="md:w-3/4 flex flex-col text-left md:pt-10 md:max-w-[60%]">
          <p className="text-lg mb-6">
            Jesteśmy kameralnym zespołem specjalistów, którzy łączą pośrednictwo w obrocie nieruchomościami, projektowanie architektoniczne, home staging, aby zaoferować naszym klientom kompleksowe wsparcie na każdym etapie współpracy.
          </p>
          <Link className="font-bold flex mt-4 hover:text-hyperlink" to='/onas'><img className="w-20 mr-2" src={arrowR} />WIĘCEJ</Link>
        </div>
      </div>

      <div>
        <h1 className="text-3xl pl-5 pb-5 mt-24 mb-8">Zrealizowane projekty</h1>
        <Karuzela />
        <div className="flex flex-row ">
          <div className="w-1/4 pb-5 flex"></div>
          <div className="w-3/4 flex">
            <div className="w-[100px] cursor-pointer">
              <Link to='/projekty'>
                <div className="flex">
                  <img className="scale-75" src={arrowR} /> <span className="pt-1 font-bold hover:text-hyperlink">PROJEKTY</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row pl-5 mt-20">
        <div className="md:w-1/4 pb-5 flex">
          <h1 className="text-3xl">Oferta</h1>
        </div>
        <div className="md:w-3/4 flex flex-col text-left md:pt-10">
          <p className="text-lg mb-2">
            Dla Klientów Sprzedających
          </p>
          <p className="text-lg mb-2">
            Dla Klientów Kupujących
          </p>
          <p className="text-lg mb-2">
            Home Staging
          </p>
          <p className="text-lg mb-4">
            Projektowanie Wnętrz
          </p>
          <div>
            <Link className="font-bold flex mt-6 hover:text-hyperlink" to='/oferta'><img className="w-20 mr-2" src={arrowR} />OFERTA</Link>
          </div>
        </div>
      </div>

      <Stopka1 />

    </div>
  );
}

export default Main;
