import Skontaktuj from "./skontaktuj";

function Stopka2() {
  return (
    <div className="flex bg-white items-center justify-center py-28 mt-20">
      <Skontaktuj />
    </div>
  );
}

export default Stopka2;
