import fot7 from "./../../../img/projekty/_melomanow/7.jpg";
import fot8 from "./../../../img/projekty/_melomanow/8.jpg";

import fot9 from "./../../../img/projekty/_melomanow/9.jpg";
import fot10 from "./../../../img/projekty/_melomanow/10.jpg";
import fot11 from "./../../../img/projekty/_melomanow/11.jpg";

import fot1 from "./../../../img/projekty/_melomanow/1.jpg";
import fot2 from "./../../../img/projekty/_melomanow/2.jpg";
import fot3 from "./../../../img/projekty/_melomanow/3.jpg";
import fot4 from "./../../../img/projekty/_melomanow/4.jpg";
import fot5 from "./../../../img/projekty/_melomanow/5.jpg";
import fot6 from "./../../../img/projekty/_melomanow/6.jpg";

function GalMelomanow() {
  return (
    <div>
      <div className="flex flex-col md:flex-row items-center md:my-10">
        <div className="md:w-1/2 flex flex-col text-left pt-20 md:pt-0 md:pr-5">
            <img className="shadow-md shadow-opacity-100 shadow-gray-900" src={fot1} />
        </div>
        <div className="md:w-1/2 flex flex-col text-left pt-20 md:pt-0 md:pl-5">
            <img className="shadow-md shadow-opacity-100 shadow-gray-900" src={fot2} />
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center md:my-10">
        <div className="md:w-1/2 flex flex-col text-left pt-20 md:pt-0 md:pr-5">
            <img className="shadow-md shadow-opacity-100 shadow-gray-900" src={fot3} />
        </div>
        <div className="md:w-1/2 flex flex-col text-left pt-20 md:pt-0 md:pl-5">
            <img className="shadow-md shadow-opacity-100 shadow-gray-900" src={fot4} />
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center md:my-10">
        <div className="md:w-1/2 flex flex-col text-left pt-20 md:pt-0 md:pr-5">
            <img className="shadow-md shadow-opacity-100 shadow-gray-900" src={fot5} />
        </div>
        <div className="md:w-1/2 flex flex-col text-left pt-20 md:pt-0 md:pl-5">
            <img className="shadow-md shadow-opacity-100 shadow-gray-900" src={fot6} />
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center md:my-10">
        <div className="md:w-1/2 flex flex-col text-left pt-20 md:pt-0 md:pr-5">
            <img className="shadow-md shadow-opacity-100 shadow-gray-900" src={fot7} />
        </div>
        <div className="md:w-1/2 flex flex-col text-left pt-20 md:pt-0 md:pl-5">
            <img className="shadow-md shadow-opacity-100 shadow-gray-900" src={fot8} />
        </div>
      </div>

      <p className="text-xl pt-10">Zdjęcia mieszkania przed zmianą</p>

      <div className="flex flex-col md:flex-row items-center md:my-10">

        <div className="md:w-1/3 flex flex-col text-left pt-20 md:pt-0 md:pr-5">
            <img className="shadow-md shadow-opacity-100 shadow-gray-900" src={fot10} />
        </div>
        <div className="md:w-1/3 flex flex-col text-left pt-20 md:pt-0 md:pl-5">
            <img className="shadow-md shadow-opacity-100 shadow-gray-900" src={fot11} />
        </div>

        <div className="md:w-1/3 flex flex-col text-left pt-20 md:pt-0 md:pl-5">
            <img className="shadow-md shadow-opacity-100 shadow-gray-900" src={fot9} />
        </div>
      </div>
    </div>
  );
}

export default GalMelomanow;
