import Stopka2 from "../utils/stopka2";
import GalPosaguSiedmiuPanien from "./galleries/gal-posagusiedmiupanien";

function PosaguSiedmiuPanien(props) {
  return (
    <>
      <div className="App-row px-5 pt-28">
        <h1 className="text-2xl font-bold">Posag Siedmiu Panien</h1>
        <p className="text-sm pb-16">Warszawa</p>
        <GalPosaguSiedmiuPanien />
        {props.children}
      </div>
      <Stopka2 />
    </>
  );
}

export default PosaguSiedmiuPanien;
