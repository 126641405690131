import { Link } from "react-router-dom";
import arrowL from "./../../../img/arr_left.png";
import arrowR from "./../../../img/arr_right.png";

function Stepper(props) {
  return (
    <div className="flex justify-between mt-32 mb-10">
      <Link className="w-2/5 sm:w-1/5 text-right text-hyperlink  hover:underline" to={props.leftArrow.link}>
        <img className="mb-4 hover:shadow-md hover:shadow-opacity-100 hover:shadow-gray-900 float-right" src={props.leftArrow.photo} />
        <p className="font-bold">{props.leftArrow.text}</p>
        <img className="w-16 mt-3 float-right" src={arrowL} />
      </Link>

      <Link className="w-2/5 sm:w-1/5 text-hyperlink  hover:underline" to={props.rightArrow.link}>
        <img className="mb-4 hover:shadow-md hover:shadow-opacity-100 hover:shadow-gray-900 float-left" src={props.rightArrow.photo} />
        <p className="font-bold">{props.rightArrow.text}</p>
        <img className="w-16 mt-3 " src={arrowR} />
      </Link>
    </div>
  );
}

export default Stepper;
