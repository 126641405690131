import './App.css';
import {
  createBrowserRouter,
  NavLink,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import Main from './pages/main';
import Offer from './pages/offer';
import Projects from './pages/projects';
import Contact from './pages/contact';
import Litewska from './pages/projects/litewska';
import Melomanow from './pages/projects/melomanow';
import Malczewskiego from './pages/projects/malczewskiego';
import Onas from './pages/onas';
import { useState } from 'react';
import Klobucka from './pages/projects/klobucka';
import Zdziarska from './pages/projects/zdziarska';
import Smolenskiego from './pages/projects/smolenskiego';
import Stepper from './pages/projects/galleries/stepper';
import { klobuckaStep, litewskaStep, malczewskiegoStep, melomanowStep, posagusiedmiupanienStep, smolenskiegoStep, zdziarskaStep } from './pages/projects/galleries/stepper-config';
import ScrollToTop from './routing';
import PosaguSiedmiuPanien from './pages/projects/posagusiedmiupanien';


const AppLayout = () => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => { setIsMenuOpen(!isMenuOpen) }

  return (
    <div className="font-mont mt-[50px]">
      <ScrollToTop />
      <header className="App-row pl-5 pr-5 ">
        <div className="flex items-center w-[100%]">
          <a href={`/`}><span className="font-bold block Logo hover:text-hyperlink">DIOGOMA NIERUCHOMOŚCI</span></a>
          <nav className="ml-4 flex-1 text-sm"  >
            <ul className="hidden md:flex flex space-x-7">
              <li>
                <NavLink
                  to="/projekty"
                  className={({ isActive }) => isActive ? "underline" : "hover:text-hyperlink"}
                >PROJEKTY</NavLink>
              </li>
              <li>
                <NavLink
                  to='/oferta'
                  className={({ isActive }) => isActive ? "underline" : "hover:text-hyperlink"}
                >OFERTA</NavLink>
              </li>
              <li>
                <NavLink
                  to='/onas'
                  className={({ isActive }) => isActive ? "underline" : "hover:text-hyperlink"}
                >O NAS</NavLink>
              </li>
              <li>
                <NavLink
                  to='/kontakt'
                  className={({ isActive }) => isActive ? "underline" : "hover:text-hyperlink"}
                >KONTAKT</NavLink>
              </li>
            </ul>


          </nav>
          <a href="https://instagram.com/monika_diogo" rel="noreferrer" target="_blank"
            className="hidden text-sm md:flex hover:text-hyperlink">INSTAGRAM</a>

          {!isMenuOpen ? <svg onClick={toggleMenu}
            fill='none'
            stroke='#587CD8'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            viewBox='0 0 24 24'
            className='w-8 h-8 md:hidden block cursor-pointer'>
            <path d="M4 6h16M4 12h16M4 18h16"></path>
          </svg> :
            <svg onClick={toggleMenu} className='w-8 h-8 md:hidden block cursor-pointer' viewBox="0 0 24 24" fill="none" stroke="#587CD8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>}
        </div>
      </header>
      {isMenuOpen &&
        <div className="
        Responsive-menu text-right absolute w-[100%]  flex flex-col md:hidden space-y-11
        shadow-lg shadow-opacity-100 shadow-gray-900">

          <div>
            <NavLink
              to="/projekty"
              onClick={toggleMenu}
              className={({ isActive }) => isActive ? "underline" : "hover:underline"}
            >PROJEKTY</NavLink>
          </div>
          <div>
            <NavLink
              to='/oferta'
              onClick={toggleMenu}
              className={({ isActive }) => isActive ? "underline" : "hover:underline"}
            >OFERTA</NavLink>
          </div>
          <div>
            <NavLink
              to='/onas'
              onClick={toggleMenu}
              className={({ isActive }) => isActive ? "underline" : "hover:underline"}
            >O NAS</NavLink>
          </div>
          <div>
            <NavLink
              to='/kontakt'
              onClick={toggleMenu}
              className={({ isActive }) => isActive ? "underline" : "hover:underline"}
            >KONTAKT</NavLink>
          </div>
          <div className="pt-10"><a href="https://instagram.com/monika_diogo" target="_blank" rel="noopener noreferrer"
            className="hover:underline">INSTAGRAM</a></div>
        </div>}
      <Outlet />
    </div>
  );
}

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <Main />,
      },
      {
        path: "/projekty",
        element: <Projects />,
      },
      {
        path: "/oferta",
        element: <Offer />,
      },
      {
        path: "/onas",
        element: <Onas />,
      },
      {
        path: "/kontakt",
        element: <Contact />,
      },

      {
        path: "/litewska",
        element: <Litewska>
          <Stepper leftArrow={posagusiedmiupanienStep} rightArrow={malczewskiegoStep} />
        </Litewska>,
      },
      {
        path: "/malczewskiego",
        element: <Malczewskiego>
          <Stepper leftArrow={litewskaStep} rightArrow={smolenskiegoStep} />
        </Malczewskiego>,
      },
      {
        path: "/smolenskiego",
        element: <Smolenskiego>
          <Stepper leftArrow={malczewskiegoStep} rightArrow={melomanowStep} />
        </Smolenskiego>,
      },
      {
        path: "/melomanow",
        element: <Melomanow>
          <Stepper leftArrow={smolenskiegoStep} rightArrow={klobuckaStep} />
        </Melomanow>,
      },
      {
        path: "/klobucka",
        element: <Klobucka>
          <Stepper leftArrow={melomanowStep} rightArrow={zdziarskaStep} />
        </Klobucka>,
      },
      {
        path: "/zdziarska",
        element: <Zdziarska>
          <Stepper leftArrow={klobuckaStep} rightArrow={posagusiedmiupanienStep} />
        </Zdziarska>,
      }
      ,
      {
        path: "/posagusiedmiupanien",
        element: <PosaguSiedmiuPanien>
          <Stepper leftArrow={zdziarskaStep} rightArrow={litewskaStep} />
        </PosaguSiedmiuPanien>,
      }
    ]
  }
]);

const App = () => <RouterProvider router={router} />;

export default App;
