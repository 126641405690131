import Skontaktuj from "./utils/skontaktuj";
import arrowR from "./../img/arr_right.png";
import Skontaktuj2 from "./utils/skontaktuj2";
import { useEffect } from "react";
import Stopka1 from "./utils/stopka1";

function Offer() {

  useEffect(() => {
    const accordionItems = document.querySelectorAll('.JS-acc-item');
    accordionItems.forEach(item => {
      const expand = item.querySelectorAll('.JS-acc-expand');
      const content = item.querySelector('.JS-acc-content');

      expand.forEach(exp => {
        exp.addEventListener('click', function () {
          if (!content.classList.contains('JS-acc-active')) {
            content.style.height = content.scrollHeight + 'px';
          } else {
            content.style.height = '0px';
          }

          expand.forEach(ajj => { ajj.classList.toggle('JS-acc-active'); })
          content.classList.toggle('JS-acc-active');
        });
      })
    });
    return () => {
      // document.querySelectorAll('.JS-acc-expand').forEach(exp => exp.removeEventListener)
      // connection.disconnect();
    };
  }, []);



  return (
    <>
      <div className="mt-28">
        <div className="pl-5 pr-5 App-row">
          <h1 className="text-2xl font-bold font-ajj">Oferta</h1>

          <div className="flex flex-col mt-6 mb-14">
            <div className="flex flex-row justify-between">
              <div className="w-1/2 pr-4">
                <p>Jako kameralna agencja nieruchomości, łączymy tradycyjne pośrednictwo  z nowoczesnym podejściem do prezentacji nieruchomości. Nasze usługi są kompleksowe i dostosowane do indywidualnych potrzeb każdego klienta.  </p>
              </div>
              <div className="w-1/2 pl-4">
                <p>Oferujemy wsparcie na każdym etapie – od wstępnej konsultacji, przez sprzedaż nieruchomości, aż po home staging, który pozwoli wydobyć pełny potencjał Twojej oferty.</p>
              </div>
            </div>
          </div>
          <Skontaktuj />

        </div>
      </div>
      <div className="JS-acc-item">
        <div className="pl-5 pr-5 App-row">
          <h1 className="text-2xl font-bold font-ajj mt-20 mb-5">Usługa dla klientów sprzedających</h1>
          <p>Nasza agencja nieruchomości oferuje pełne wsparcie w procesie sprzedaży nieruchomości. Oferujemy usługi, które nie tylko pomogą Ci szybko znaleźć odpowiedniego kupca, ale także zapewnią, że transakcja będzie przebiegać sprawnie i zgodnie z przepisami.</p>
          <div className="JS-acc-expand inline-block cursor-pointer mt-5 md:pl-28 hover:text-hyperlink" ><img className="w-20 mr-2 inline-block" src={arrowR} />ROZWIŃ</div>
        </div>

        <div className="JS-acc-content bg-white">
          <div className="App-row px-5 py-10 md:pl-28">
            <p className="font-bold py-5">Profesjonalna wycena nieruchomości</p>
            <p className="md:pl-28">Jesteśmy na bieżąco z rynkiem nieruchomości w Warszawie, współpracujemy z doświadczonymi rzeczoznawcami majątkowymi, którzy przygotują dla Ciebie rzetelną wycenę nieruchomości. Dzięki temu będziesz miał pewność, że sprzedajesz ją w optymalnej cenie.</p>
            <p className="font-bold py-5">Skuteczna kampania marketingowa</p>
            <p className="md:pl-28">Przygotowujemy kompleksową strategię marketingową, która obejmuje profesjonalne zdjęcia, wideo prezentacje, ogłoszenia na najpopularniejszych portalach i mediach społecznościowych. Dzięki temu Twoja nieruchomość dotrze do szerokiego grona potencjalnych kupców.</p>
            <p className="font-bold py-5">Home staging i profesjonalna fotografia</p>
            <p className="md:pl-28">Jako architektka i home stagerka zadbamy o to, aby Twoja nieruchomość wyglądała jak najlepiej na zdjęciach i podczas prezentacji. Poprawiamy wygląd wnętrz poprzez odpowiednie aranżacje, oświetlenie oraz dekoracje, aby zwiększyć atrakcyjność nieruchomości.</p>
            <p className="font-bold py-5">Negocjacje i obsługa prawna</p>
            <p className="md:pl-28">Pomagamy Ci w negocjacjach z kupcami, a także pełną obsługę prawną, gwarantującą bezpieczne zawarcie umowy sprzedaży.</p>
            <div className="flex place-items-center justify-between pt-10 md:pl-28">
              <div className="JS-acc-expand JS-acc-active flex cursor-pointer mt-5 hover:text-hyperlink" ><img className="w-20 mr-2" src={arrowR} />ZWIŃ</div>
              <div className="mt-3">
                <Skontaktuj2></Skontaktuj2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="JS-acc-item">
        <div className="pl-5 pr-5 App-row">
          <h1 className="text-2xl font-bold font-ajj mt-20 mb-5">Usługa dla klientów kupujących</h1>
          <p>Nasza agencja nieruchomości pomaga w znalezieniu i zakupie idealnej nieruchomości, która spełni wszystkie Twoje oczekiwania. Oferujemy szeroki zakres usług, które ułatwią Ci ten proces.</p>
          <div className="JS-acc-expand flex cursor-pointer mt-5 md:pl-28 hover:text-hyperlink" ><img className="w-20 mr-2" src={arrowR} />ROZWIŃ</div>
        </div>

        <div className="JS-acc-content bg-white">
          <div className="App-row px-5 py-10 md:pl-28">
            <p className="font-bold py-5">Personalizowane wyszukiwanie ofert</p>
            <p className="md:pl-28">Na podstawie Twoich potrzeb i preferencji, dobierzemy dla Ciebie najbardziej odpowiednie oferty nieruchomości. Korzystamy  z szerokiej bazy danych oraz współpracujemy z innymi agencjami, aby zapewnić Ci dostęp do najlepszych ofert na rynku.</p>
            <p className="font-bold py-5">Indywidualne konsultacje z architektem</p>
            <p className="md:pl-28">Oferujemy możliwość skorzystania z konsultacji z naszym architektem, który pomoże Ci ocenić potencjał wybranej nieruchomości oraz zaplanować ewentualne zmiany czy remonty, aby jak najlepiej dopasować ją do Twoich potrzeb.</p>
            <p className="font-bold py-5">Organizacja prezentacji nieruchomości</p>
            <p className="md:pl-28">Zajmiemy się organizacją wizyt i prezentacji nieruchomości, abyś mógł na spokojnie ocenić każdą z nich. Dostosowujemy się do Twojego harmonogramu, oferując elastyczne terminy.</p>
            <p className="font-bold py-5">Negocjacje cenowe i wsparcie prawne</p>
            <p className="md:pl-28">Nasi agenci będą reprezentować Twoje interesy podczas negocjacji cenowych z właścicielami, a także zapewnią pełne wsparcie prawne na każdym etapie zakupu, od umowy przedwstępnej aż po akt notarialny.</p>
            <p className="md:pl-28 pt-10 font-bold">Te usługi są projektowane z myślą o Twoim komforcie oraz bezpieczeństwie, aby zakup lub sprzedaż nieruchomości były jak najbardziej efektywne i bezstresowe. Współpracując z nami, masz pewność, że każda transakcja będzie dopięta na ostatni guzik.</p>
            <div className="flex place-items-center justify-between pt-10 md:pl-28">
              <div className="JS-acc-expand JS-acc-active flex cursor-pointer mt-5 hover:text-hyperlink" ><img className="w-20 mr-2" src={arrowR} />ZWIŃ</div>
              <div className="mt-3">
                <Skontaktuj2></Skontaktuj2>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="JS-acc-item">
        <div className="pl-5 pr-5 App-row">
          <h1 className="text-2xl font-bold font-ajj mt-20 mb-5">Home Staging</h1>
          <p>Nasza usługa home stagingu ma na celu maksymalizację wartości  i atrakcyjności Twojej nieruchomości na rynku. Przygotujemy wnętrza  w taki sposób, aby przyciągnęły uwagę potencjalnych kupców i umożliwiły szybszą sprzedaż za możliwie najwyższą cenę.</p>
          <div className="JS-acc-expand inline-block cursor-pointer mt-5 md:pl-28 hover:text-hyperlink" ><img className="w-20 mr-2 inline-block" src={arrowR} />ROZWIŃ</div>
        </div>

        <div className="JS-acc-content bg-white">
          <div className="App-row px-5 py-10 md:pl-28">
            <p className="font-bold py-5">Analiza nieruchomości i doradztwo</p>
            <p className="md:pl-28">Przeprowadzimy szczegółową analizę Twojej nieruchomości, aby zidentyfikować jej najmocniejsze strony oraz obszary, które wymagają poprawy. Na podstawie tej oceny przygotujemy spersonalizowany plan działań.</p>
            <p className="font-bold py-5">Aranżacja wnętrz</p>
            <p className="md:pl-28">Wprowadzimy zmiany w układzie mebli, oświetleniu, dodatkach oraz kolorystyce, aby wnętrza były bardziej przestronne, funkcjonalne  i estetyczne. Nasze aranżacje są skierowane na wyeksponowanie potencjału nieruchomości, tak aby każdy odwiedzający mógł wyobrazić sobie w niej swoje życie.</p>
            <p className="font-bold py-5">Zakup lub wypożyczenie dekoracji i mebli</p>
            <p className="md:pl-28">Oferujemy możliwość zakupu albo wypożyczenia mebli i dekoracji, które idealnie wpiszą się w charakter nieruchomości. Dzięki temu wnętrza będą wyglądać profesjonalnie, co pomoże wyróżnić ofertę na tle innych.</p>
            <p className="font-bold py-5">Profesjonalna fotografia i materiały marketingowe</p>
            <p className="md:pl-28">Po zakończonym etapie home stagingu, organizujemy sesję zdjęciową wykonaną przez doświadczonych fotografów. Zdjęcia  o wysokiej jakości staną się fundamentem skutecznej kampanii marketingowej Twojej nieruchomości.</p>
            <p className="font-bold py-5">Optymalizacja kosztów</p>
            <p className="md:pl-28">Nasze usługi są zaprojektowane tak, aby inwestycja w home staging zwróciła się poprzez szybszą sprzedaż nieruchomości oraz wyższą finalną cenę transakcji. Każda propozycja aranżacji jest starannie dopasowana do Twojego budżetu.</p>
            <div className="flex place-items-center justify-between pt-10 md:pl-28">
              <div className="JS-acc-expand JS-acc-active flex cursor-pointer mt-5 hover:text-hyperlink" ><img className="w-20 mr-2" src={arrowR} />ZWIŃ</div>
              <div className="mt-3">
                <Skontaktuj2></Skontaktuj2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="JS-acc-item">
        <div className="pl-5 pr-5 App-row">
          <h1 className="text-2xl font-bold font-ajj mt-20 mb-5">Projektowanie wnętrz</h1>
          <p>Z naszą pomocą zamienisz swoją starą nieruchomość w miejsce pełne stylu, funkcjonalności i wyjątkowego charakteru. Bez względu na to, czy planujesz całkowity remont, czy subtelną metamorfozę, pomożemy Ci stworzyć przestrzeń, w której będziesz dumny i poczujesz się jak w domu.</p>
          <div className="JS-acc-expand inline-block cursor-pointer mt-5 md:pl-28 hover:text-hyperlink" ><img className="w-20 mr-2 inline-block" src={arrowR} />ROZWIŃ</div>
        </div>

        <div className="JS-acc-content bg-white">
          <div className="App-row px-5 py-10 md:pl-28">
            <p className="font-bold py-5">Konsultacje projektowe i analiza potrzeb</p>
            <p className="md:pl-28">Rozpoczynamy od indywidualnych konsultacji, podczas których omawiamy Twoje oczekiwania, preferencje oraz styl życia. Na tej podstawie opracowujemy spersonalizowany plan projektowy, który najlepiej odpowiada Twoim potrzebom.</p>
            <p className="font-bold py-5">Kompleksowy projekt wnętrza</p>
            <p className="md:pl-28">Opracowujemy pełny projekt aranżacji wnętrza, który obejmuje układ funkcjonalny, dobór mebli, oświetlenia, kolorów oraz materiałów wykończeniowych. Nasze projekty są nie tylko estetyczne, ale także praktyczne i dopasowane do Twojego stylu życia.</p>
            <p className="font-bold py-5">Wizualizacje 3D</p>
            <p className="md:pl-28">Przygotowujemy realistyczne wizualizacje 3D, które pozwolą Ci zobaczyć efekt końcowy jeszcze przed rozpoczęciem prac. Dzięki temu masz pełną kontrolę nad wyglądem swojego przyszłego wnętrza i możesz wprowadzać ewentualne korekty na wczesnym etapie.</p>
            <p className="font-bold py-5">Nadzór autorski i koordynacja prac</p>
            <p className="md:pl-28">Zapewniamy pełny nadzór nad realizacją projektu, koordynując prace wykonawcze oraz dostawy materiałów. Nasi projektanci będą monitorować każdy etap, aby zagwarantować, że efekt końcowy będzie zgodny z ustaleniami.</p>
            <p className="font-bold py-5">Zakupy i dobór wyposażenia</p>
            <p className="md:pl-28">Pomagamy w doborze i zakupie mebli, oświetlenia oraz dodatków. Współpracujemy z zaufanymi dostawcami, co pozwala nam zaoferować Ci produkty wysokiej jakości w konkurencyjnych cenach.</p>
            <p className="font-bold py-5">Projekty dla każdego budżetu</p>
            <p className="md:pl-28">Nasze usługi są elastyczne i dostosowane do Twojego budżetu. Oferujemy zarówno kompleksowe projekty "pod klucz", jak  i pojedyncze konsultacje czy drobne zmiany aranżacyjne.</p>
            <p className="md:pl-28 mt-10 font-bold">Naszym celem jest stworzenie przestrzeni, która będzie idealnie odzwierciedlała Twój styl oraz potrzeby, a jednocześnie będzie funkcjonalna i ponadczasowa.</p>
            <div className="flex place-items-center justify-between pt-10 md:pl-28">
              <div className="JS-acc-expand JS-acc-active flex cursor-pointer mt-5 hover:text-hyperlink" ><img className="w-20 mr-2" src={arrowR} />ZWIŃ</div>
              <div className="mt-3">
                <Skontaktuj2></Skontaktuj2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="App-row md:w-[700px]">
        <div className="bg-white mt-28 mb-20 px-20 pt-16 pb-20 rounded-2xl ">
          <h1 className="font-ajj text-2xl pb-5 p text-center">Co nas wyróżnia?</h1>
          <p className="pb-3">W naszej agencji nieruchomości stawiamy na kompleksowe podejście – łączymy sprzedaż nieruchomości z usługami architektonicznymi i home stagingiem, aby maksymalnie podnieść atrakcyjność oferty.</p>
          <p className="pb-3">Współpracujemy z klientami w sposób empatyczny, słuchając ich potrzeb i wspólnie szukając najlepszych rozwiązań.</p>
          <p>Prowizję pobieramy tylko od zleceniodawcy, dzięki czemu transakcje są jasne  i uczciwe. Wierzymy, że nasza praca nie tylko pomaga w sprzedaży, ale także podnosi standardy na rynku nieruchomości.</p>

          <h1 className="font-ajj text-2xl pb-5 pt-14 text-center">Dlaczego my?</h1>
          <p className="pb-3">Wybierając naszą agencję, zyskujesz partnera, który troszczy się  o Twoje interesy z pełnym zaangażowaniem. Łączymy wiedzę  z zakresu pośrednictwa, architektury i home stagingu, aby dostarczyć usługi najwyższej jakości.</p>
          <p>Nasze podejście jest indywidualne, pełne empatii i skupione na Twoich potrzebach. Z nami proces sprzedaży lub wynajmu nieruchomości stanie się prosty i efektywny, a Ty osiągniesz zamierzony cel.</p>

          <div className="text-center pt-14">
            <Skontaktuj />
          </div>
        </div>
      </div>

      <div className="App-row">
        <Stopka1 />
      </div>


    </>
  );
}

export default Offer;

