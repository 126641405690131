import Stopka2 from '../utils/stopka2';
import GalLitewska from './galleries/gal-litewska'

function Litewska(props) {
  return (
    <>
      <div className="App-row px-5 pt-28">
        <h1 className="text-2xl font-bold">Litewska</h1>
        <p className="text-sm pb-16">Warszawa</p>
        <GalLitewska />
        { props.children }
      </div>
      <Stopka2 />
    </>
  );
}

export default Litewska;
