import footfoto from "./../img/people/foot_foto.jpg";
import woman1 from "./../img/people/woman1.jpg";
import woman2 from "./../img/people/woman2.jpg";
import man1 from "./../img/people/man1.jpg";
import man2 from "./../img/people/man2.jpg";
import { Link } from "react-router-dom";
import Stopka2 from "./utils/stopka2";

function Onas() {
  return (
    <div className="mt-28">
      <div className="App-row">
        <h1 className="text-2xl pl-5 font-ajj">Nasza agencja to miejsce, gdzie pasja spotyka się z profesjonalizmem. </h1>
        <div className="flex flex-col md:flex-row pl-5">
          <div className="md:w-1/4 pb-5">
          </div>
          <div className="md:w-3/4 flex flex-col text-left md:pt-14 md:max-w-[60%]">
            <p className="text-md mb-6">
              Jesteśmy kameralnym zespołem specjalistów, którzy łączą pośrednictwo w obrocie nieruchomościami, projektowanie architektoniczne,  home staging, aby zaoferować naszym klientom kompleksowe wsparcie na każdym etapie współpracy.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white mt-10">
        <div className="App-row  flex flex-col md:flex-row items-center mt-10 items-stretch">
          <div className="md:w-3/4 pb-5 pb-20 pr-10  pl-5 pt-16 pr-28">
            <p className="text-md mb-6">
              Wierzymy, że każda nieruchomość ma swoją unikalną historię,  a naszym celem jest odkrycie i podkreślenie jej wyjątkowego charakteru. Z pełnym zaangażowaniem i empatią podchodzimy  do każdej współpracy, słuchając potrzeb naszych klientów  i dostosowując nasze działania do ich oczekiwań.
            </p>
            <p className="text-md mb-6">
              Dzięki naszemu doświadczeniu i indywidualnemu podejściu, tworzymy nową jakość w branży nieruchomości, pomagając klientom osiągać ich cele, realizować marzenia o idealnym miejscu do życia lub inwestycji.
            </p>
            <Link to="/kontakt">
              <p className="text-md mb-6 font-bold text-hyperlink inline-block">
                Poznajmy się.
              </p>
            </Link>
          </div>
          <div className="md:w-1/4 flex flex-col text-left">
            <img className="" src={footfoto} />
          </div>
        </div>
      </div>

      <div className="App-row pt-8">
        <div className="container mx-auto flex flex-wrap justify-center">
          <div className="w-full md:w-1/2 p-14">
            <img className="" src={woman1} />
            <p className="font-bold text-lg md:text-center mt-10 mb-10">Monika Diogo- Badura</p>
            <p className="text-sm">Z wykształcenia prawnik, licencjonowana agentka nieruchomości i home stagerka z wieloletnim doświadczeniem. Od kilku lat z pasją pomaga klientom w sprzedaży i wynajmie nieruchomości, dbając o to, aby wnętrza były nie tylko funkcjonalne, ale i estetyczne. Dzięki połączeniu wiedzy prawnej, znajomości rynku nieruchomości oraz umiejętności aranżacji wnętrz, zapewnia kompleksowe wsparcie na każdym etapie transakcji. Praca oraz aranżacja przestrzeni to jej prawdziwa pasja.</p>
          </div>
          <div className="w-full md:w-1/2 p-14">
            <img className="" src={woman2} />
            <p className="font-bold text-lg md:text-center mt-10 mb-10">Barbara Mąka</p>
            <p className="text-sm">Architektka, absolwentka Wydziału Architektury  i Urbanistyki Politechniki Krakowskiej oraz Politecnico di Milano. Doświadczenie zawodowe zdobyła pracując przy projektach wielobranżowych za granicą oraz w renomowanych warszawskich pracowniach m.in. Medusa Group oraz Mąka- Sojka Architekci. Pasją Barbary jest dizajn, projektowanie biżuterii oraz aranżacja wnętrz.</p>
          </div>
        </div>
        <div className="container mx-auto flex flex-wrap justify-center">
          <div className="w-full md:w-1/2 p-14 pt-8">
            <img className="" src={man1} />
            <p className="font-bold text-lg md:text-center mt-10 mb-10">Antonio Diogo</p>
            <p className="text-sm">Inżynier budownictwa z uprawnieniami budowlanymi w Polsce i Portugalii, w naszej firmie odpowiedzialny za doradztwo od strony budowlanej i technicznej, a także nadzór prac budowlano-remontowych.</p>
          </div>
          <div className="w-full md:w-1/2 p-14 pt-8">
            <img className="" src={man2} />
            <p className="font-bold text-lg md:text-center mt-10 mb-10">Robert Badura</p>
            <p className="text-sm">Specjalista ds. Marketingu z ponad dziesięcioletnim doświadczeniem w branży IT, gdzie pracował dla takich gigantów jak Microsoft i Amazon Web Services. W tych firmach zarządzał sprzedażą rozwiązań chmurowych w Polsce oraz w Europie Środkowo-Wschodniej.</p>
          </div>
        </div>
      </div>
      <Stopka2></Stopka2>
    </div>
  );
}

export default Onas;
