import { Link } from "react-router-dom";
import klobucka from "./../img/projekty/klobucka.jpg";
import litewska from "./../img/projekty/litewska.jpg";
import malczewskiego from "./../img/projekty/malczewskiego.jpg";
import melomanow from "./../img/projekty/melomanow.jpg";
import smolenskiego from "./../img/projekty/smolenskiego.jpg";
import zdziarska from "./../img/projekty/zdziarska.jpg";
import posagusiedmiupanien from "./../img/projekty/posagusiedmiupanien.jpg";
import Stopka2 from "./utils/stopka2";

function Projects() {
  return (
    <>
      <div className="App-row">

        <div className="flex flex-col md:flex-row pl-5 mt-28">
          <h1 className="text-2xl font-bold font-ajj">Zrealizowane projekty</h1>
          <div className="md:w-3/4 flex flex-col text-left md:pt-10 md:max-w-[60%]">
            <p className="text-md mt-14">
              Każde mieszkanie, które trafia w nasze ręce, to dla nas wyjątkowy projekt. Zajmujemy się tylko kilkoma nieruchomościami jednocześnie, co pozwala nam poświęcić każdej z nich maksymalną uwagę i czas.
            </p>
          </div>
        </div>

        {/* ROW */}
        <div className="flex flex-col md:flex-row px-5 items-center md:my-10">
          <div className="md:w-1/2 flex flex-col text-left pt-20 md:pt-10 md:px-10">
            <Link className="text-hyperlink hover:underline group-hover:text-hyperlink cursor-pointer" to={`/litewska`}>
              <img src={litewska} />
              <p className="text-main-txt mt-5 text-hyperlink text-sm">WARSZAWA</p>
              <p className="pt-1 text-2xl text-hyperlink ">Litewska</p>
            </Link>
          </div>
          <div className="md:w-1/2 flex flex-col text-left pt-20 md:pt-10 md:px-10">
            <Link className="text-hyperlink hover:underline cursor-pointer" to={`/malczewskiego`}>
              <img src={malczewskiego} />
              <p className="text-main-txt mt-5 text-hyperlink text-sm">WARSZAWA</p>
              <p className="pt-1 text-2xl text-hyperlink ">Malczewskiego</p>
            </Link>
          </div>
        </div>

        <div className="flex flex-col md:flex-row px-5">
          <div className="flex-1"></div>
          <div className="md:w-3/4 flex flex-col text-right md:max-w-[60%]">
            <p className="text-md mt-20 md:mt-5 md:px-10">
              Niezależnie od budżetu, którym dysponuje nasz zleceniodawca, staramy się o stworzenie atmosfery, która sprawia, że wnętrza przyciągają uwagę. Naszym celem jest podkreślenie potencjału każdej nieruchomości i przyspieszenie jej sprzedaży lub wynajmu.
            </p>
          </div>
        </div>

        {/* ROW */}
        <div className="flex flex-col md:flex-row px-5 items-center md:my-10">
          <div className="md:w-1/2 flex flex-col text-left pt-20 md:pt-10 md:px-10 ">
            <Link className="text-hyperlink hover:underline cursor-pointer " to={`/smolenskiego`}>
              <img src={smolenskiego} />
              <p className="text-main-txt mt-5 text-hyperlink text-sm">WARSZAWA</p>
              <p className="pt-1 text-2xl text-hyperlink ">Smoleńskiego</p>
            </Link>
          </div>
          <div className="md:w-1/2 flex flex-col text-left pt-20 md:pt-10 md:px-10">
            <Link className="text-hyperlink hover:underline cursor-pointer" to={`/melomanow`}>
              <img src={melomanow} />
              <p className="text-main-txt mt-5 text-hyperlink text-sm">WARSZAWA</p>
              <p className="pt-1 text-2xl text-hyperlink ">Melomanów</p>
            </Link>
          </div>
        </div>

        <div className="flex flex-col md:flex-row px-5 items-center md:my-10">
          <div className="md:w-1/2 flex flex-col text-left pt-20 md:pt-10 md:px-10">
            <Link className="text-hyperlink hover:underline cursor-pointer" to={`/klobucka`}>
              <img src={klobucka} />
              <p className="text-main-txt mt-5 text-hyperlink text-sm">WARSZAWA</p>
              <p className="pt-1 text-2xl text-hyperlink ">Kłobucka</p>
            </Link>
          </div>
          <div className="md:w-1/2 flex flex-col text-left pt-20 md:pt-10 md:px-10 ">
            <Link className="text-hyperlink hover:underline cursor-pointer" to={`/zdziarska`}>
              <img src={zdziarska} />
              <p className="text-main-txt mt-5 text-hyperlink text-sm">WARSZAWA</p>
              <p className="pt-1 text-2xl text-hyperlink ">Zdziarska</p>
            </Link>
          </div>
        </div>

        <div className="flex flex-col md:flex-row px-5 items-center md:my-10">
          <div className="md:w-1/2 flex flex-col text-left pt-20 md:pt-10 md:px-10 ">
            <Link className="text-hyperlink hover:underline cursor-pointer" to={`/posagusiedmiupanien`}>
              <img src={posagusiedmiupanien} />
              <p className="text-main-txt mt-5 text-hyperlink text-sm">WARSZAWA</p>
              <p className="pt-1 text-2xl text-hyperlink ">Posag Siedmiu Panien</p>
            </Link>
          </div>
        </div>
      </div>
      <Stopka2></Stopka2>
    </>
  );
}

export default Projects;
