import Stopka2 from "../utils/stopka2";
import GalKlobucka from "./galleries/gal-klobucka";

function Klobucka(props) {
  return (
    <>
      <div className="App-row px-5 pt-28">
        <h1 className="text-2xl font-bold">Kłobucka</h1>
        <p className="text-sm pb-16">Warszawa</p>
        <GalKlobucka />
        { props.children }
      </div>
      <Stopka2 />
    </>
  );
}

export default Klobucka;
