import { Link } from "react-router-dom";
import footfoto from "./../../img/people/foot_foto.jpg";

function Stopka1() {
  return (
    <div className="flex flex-col md:flex-row px-5 items-stretch mt-20">
      <div className="md:w-1/2 flex flex-col text-left">
        <img src={footfoto} />
      </div>
      <div className="md:w-1/2 pb-5 pb-20 pr-10 pt-20 bg-white flex items-center flex-col text-sm sm:pb-20">
        <div>
          <p className="text-lg mb-6 Logo font-bold">
            DIOGOMA NIERUCHMOŚCI
          </p>
          <p className="mb-4">
            <span>Diogoma Nieruchomości</span><br />
            <span>Fort Służew 3/7</span><br />
            <span>02-782 Warszawa</span>
          </p>

          <p className="font-ajj text-lg mb-4 font-bold">kontakt</p>
          <p className="mb-4">
            <span><a href="mailto: monika@diogoma.pl">monika@diogoma.pl</a></span><br />
            <span><a href="mailto: barbara@diogoma.pl">barbara@diogoma.pl</a></span><br />
            <span>Monika: <a href="tel:+48513199791">+48 513 199 791</a></span><br />
            <span>Barbara: <a href="tel:+48669089189">+48 669 089 189</a></span>
          </p>

          <p className="font-ajj text-lg mb-4 font-bold">strona</p>
          <p className="mb-4">
            <Link to='/projekty'><span className="hover:underline">projekty</span></Link><br />
            <Link to='/oferta'><span className="hover:underline">oferta</span></Link><br />
            <Link to='/onas'><span className="hover:underline">o nas</span></Link><br />
            <Link to='/kontakt'><span className="hover:underline">kontakt</span></Link>
          </p>
        </div>
        <div className="hidden">
        <span>Coded by Daniel Malek, designed by Emilia Marcinowska</span>
        </div>
      </div>
    </div>
  );
}

export default Stopka1;
