
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import caru1 from "./../img/karuzela/k1.jpg";
import caru2 from "./../img/karuzela/k2.jpg";
import caru3 from "./../img/karuzela/k3.jpg";
import caru4 from "./../img/karuzela/k4.jpg";
import caru5 from "./../img/karuzela/k5.jpg";
import caru6 from "./../img/karuzela/k6.jpg";
import caru7 from "./../img/karuzela/k7.jpg";
import caru8 from "./../img/karuzela/k8.jpg";
import arrowR from "./../img/arr_right.png";
import arrowL from "./../img/arr_left.png";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const CustomRightArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType }
  } = rest;
  // onMove means if dragging or swiping in progress.
  return <div className="Carusel-arrow-right scale-75 cursor-pointer" onClick={() => onClick()}
    style={{
      top: (document.querySelector('.Carousel-item')?.clientHeight || 0) + 10,
      left: (document.querySelector('.carousel-container')?.clientWidth || 0) * 0.153 + 100
    }}
  >
    <img src={arrowR} />
  </div>;
};

const CustomLeftArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType }
  } = rest;
  // onMove means if dragging or swiping in progress.
  return <div className="Carusel-arrow-left scale-75 cursor-pointer" onClick={() => onClick()}
    style={{
      top: (document.querySelector('.Carousel-item')?.clientHeight || 0) + 10,
      left: (document.querySelector('.carousel-container')?.clientWidth || 0) * 0.153
    }}
  >
    <img src={arrowL} />
  </div>;
};


function Karuzela() {
  // const [dynamicArrowStyles, setDynamicArrowStyles] = useState({
  //   top: (document.querySelector('.Carousel-item')?.clientHeight || 0) + 50,
  //   left: (document.querySelector('.carousel-container')?.clientWidth || 0) * 0.15 + 100
  // });

  // useEffect(() => {
  //   setDynamicArrowStyles({
  //     top: (document.querySelector('.Carousel-item')?.clientHeight || 0) + 50,
  //     left: (document.querySelector('.carousel-container')?.clientWidth || 0) * 0.15 + 100
  //   });

  //   const handleResize = () => {
  //     setDynamicArrowStyles({
  //       top: (document.querySelector('.Carousel-item')?.clientHeight || 0) + 50,
  //       left: (document.querySelector('.carousel-container')?.clientWidth || 0) * 0.15 + 100
  //     });
  //   };

  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, [document.querySelector('.Carousel-item')]);

  return (
    <div className="relative">
      <Carousel
        swipeable={true}
        draggable={false}
        showDots={false}
        responsive={responsive}
        ssr={false}
        infinite={true}
        autoPlay={false}
        autoPlaySpeed={5000}
        keyBoardControl={true}
        containerClass="carousel-container items-start"
        sliderClass="items-start"
        itemClass="Carousel-item carousel-item-padding-40-px px-4"
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
        className="pb-16"
        centerMode={true}
      >
        <img src={caru1} />
        <img src={caru2} />
        <img src={caru3} />
        <img src={caru4} />
        <img src={caru5} />
        <img src={caru6} />
        <img src={caru7} />
        <img src={caru8} />
      </Carousel>

      {/* <div className="Carusel-arrow-right  cursor-pointer font-bold"
        style={dynamicArrowStyles}
      >
        <Link to='/projekty'>
          <div className="flex">
            <img className="scale-75" src={arrowR} /> <span className="pt-1 font-bold">PROJEKTY</span>
          </div>
        </Link>
      </div> */}

    </div >
  );
}

export default Karuzela;
