import footfoto from "./../img/people/foot_foto.jpg";

function Contact() {
  return (
    <div className="bg-white mt-24">
      <div className="App-row ">
        <div className="flex flex-col md:flex-row px-5 items-center">
          <div className="md:w-1/2">
            <h1 className="text-3xl pt-20 font-ajj float-right">Pozostańmy w kontakcie! </h1>
          </div>
          <div className="md:w-1/2 flex flex-col text-left pt-10 md:pl-10">
            <div></div>
          </div>
        </div>
      </div>
      <div className="App-row">
        <div className="flex flex-col md:flex-row pl-5 pr-4 items-stretch md:mt-20 md:flex-row-reverse">
          <div className="
          text-md lg:text-lg md:w-1/2 pt-10 md:pt-20 md:pt-0 pb-5
          pl-0 sm:pl-10 md:pl-8 lg:pl-28 
          pb-20 pr-10 bg-white flex gap-1
          ">
            <div className="w-1/2 flex flex-col gap-3">
              <p className="font-bold pb-3">MAIL</p>
              <p className="font-bold">&nbsp;</p>
              <p className="font-bold">TELEFON</p>
              <p className="font-bold">&nbsp;</p>
              <p className="font-bold pb-7">&nbsp;</p>

              <p className="font-bold">SIEDZIBA</p>
              <p className="font-bold">&nbsp;</p>
              <p className="font-bold">&nbsp;</p>
              <p className="font-bold">&nbsp;</p>
              <p className="font-bold">INSTAGRAM</p>
            </div>
            <div className="w-1/2 flex flex-col flex-row gap-3 pb-20">
              <p className=""><a href= "mailto: monika@diogoma.pl">monika@diogoma.pl</a></p>
              <p className="pb-3"><a href= "mailto: barbara@diogoma.pl">barbara@diogoma.pl</a></p>
              <p>Monika <br /><a href="tel:+48513199791"> +48 513 199 791</a></p>
              <p className="pb-3">Barbara <br /><a href="tel:+48669089189">+48 669 089 189</a></p>
              <p>Diogoma<br />Nieruchomości</p>
              <p>Fort Służew 3/7</p>
              <p className="pb-3">02-782 Warszawa</p>
              <a href="https://instagram.com/monika_diogo" target="_blank"
                className="text-lg flex hover:text-hyperlink">monika_diogo</a>
            </div>
          </div>
          <div className="md:w-1/2 flex flex-col text-left">
            <img src={footfoto} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
